<template>
  <div class="hero-head non-printable">
    <nav class="breadcrumbs is-hidden-touch" aria-label="breadcrumbs">
      <ul>
        <li
          :class="{
            valid: stepIsValid('chooseRoof'),
            active: activeStep === 0,
          }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
              fill="currentColor"
            />
          </svg>
          <button @click="goToStep(0)">1. {{ $t('step.roof_type') }}</button>
          <table v-if="stepIsValid('chooseRoof')">
            <tr>
              <td>{{ $t('breadcrumb.roof_type') }}:</td>
              <td>{{ formatValue(getSelectedValue('rooftype')) }}</td>
            </tr>
          </table>
        </li>
        <li
          :class="{
            valid: stepIsValid('chooseRoofTile'),
            active: activeStep === 1,
          }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="green">
            <path
              d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
              fill="currentColor"
            />
          </svg>
          <button @click="goToStep(1)" :class="{ active: stepIsValid('chooseRoof') }">2. {{ $t('step.roof_tile') }}</button>
          <table v-if="stepIsValid('chooseRoofTile')">
            <tr v-if="formatValue(getSelectedValue('rooftile'))">
              <td>{{ $t('breadcrumb.roof_tile') }}:</td>
              <td>{{ formatValue(getSelectedValue('rooftile')) }}</td>
            </tr>
            <tr v-if="formatValue(getSelectedValue('rooftileTreatment'))">
              <td>{{ $t('breadcrumb.tile_treatment') }}:</td>
              <td>{{ formatValue(getSelectedValue('rooftileTreatment')) }}</td>
            </tr>
            <tr v-if="formatValue(getSelectedValue('rooftileColor'))">
              <td>{{ $t('breadcrumb.tile_color') }}:</td>
              <td>{{ formatValue(getSelectedValue('rooftileColor')) }}</td>
            </tr>
          </table>
        </li>
        <li
          :class="{
            valid: stepIsValid('safeProducts'),
            active: activeStep === 2,
          }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="green">
            <path
              d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
              fill="currentColor"
            />
          </svg>
          <button v-on:click="goToStep(2)">3. {{ $t('step.roof_security') }}</button>
          <table v-if="stepIsValid('safeProducts')">
            <tr v-if="formatValue(getSelectedValue('roofSafetyLengths'))">
              <td>{{ $t('breadcrumb.roof_safety_length') }}:</td>
              <td>{{ formatValue(getSelectedValue('roofSafetyLengths')) }}</td>
            </tr>
            <tr v-if="formatValue(getSelectedValue('roofBridgeLengths'))">
              <td>{{ $t('breadcrumb.roof_bridge_length') }}:</td>
              <td>{{ formatValue(getSelectedValue('roofBridgeLengths')) }}</td>
            </tr>
            <tr v-if="formatValue(getSelectedValue('roofRailLengths'))">
              <td>{{ $t('breadcrumb.roof_rail_length') }}:</td>
              <td>{{ formatValue(getSelectedValue('roofRailLengths')) }}</td>
            </tr>
            <tr v-if="formatValue(getSelectedValue('roofHatchSafetyAmount'))">
              <td>{{ $t('breadcrumb.roof_hatch_safety_amount') }}:</td>
              <td>{{ formatValue(getSelectedValue('roofHatchSafetyAmount')) }}</td>
            </tr>
          </table>
        </li>
        <li
          :class="{
            valid: stepIsValid('alternatives'),
            active: activeStep === 3,
          }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="green">
            <path
              d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
              fill="currentColor"
            />
          </svg>
          <button v-on:click="goToStep(3)">4. {{ $t('step.alternatives') }}</button>
          <table v-if="stepIsValid('alternatives')">
            <tr v-if="formatValue(getSelectedValue('accessoryColor'))">
              <td>{{ $t('breadcrumb.accessory_color') }}:</td>
              <td>{{ formatValue(getSelectedValue('accessoryColor')) }}</td>
            </tr>
            <tr v-if="formatValue(getSelectedValue('roofTilt'))">
              <td>{{ $t('breadcrumb.roof_angle') }}:</td>
              <td>{{ formatValue(getSelectedValue('roofTilt')) }}°</td>
            </tr>
            <tr v-if="formatValue(getSelectedValue('roofLength'))">
              <td>{{ $t('breadcrumb.roof_pitch') }}:</td>
              <td>{{ formatValue(getSelectedValue('roofLength')) }}m</td>
            </tr>
            <tr v-if="formatValue(getSelectedValue('snowZone'))">
              <td>{{ $t('breadcrumb.snow_zone') }}:</td>
              <td>{{ formatValue(getSelectedValue('snowZone')) }}</td>
            </tr>
          </table>
        </li>
        <li
          :class="{
            valid: validSteps.length === 5,
            active: activeStep === 4,
          }"
        >
          <button v-on:click="validSteps.length === 5 ? goToStep(4) : null" :class="{ disabled: validSteps.length < 5 }">
            {{ $t('breadcrumb.summary') }}
          </button>
          <table v-if="validSteps.length < 5">
            <tr>
              <th>{{ $t('breadcrumb.incomplete_steps') }}</th>
            </tr>
            <tr v-if="!this.validSteps.includes('chooseRoof')">
              <td>1. {{ $t('step.roof_type').toUpperCase() }}</td>
            </tr>
            <tr v-if="!this.validSteps.includes('chooseRoofTile')">
              <td>2. {{ $t('step.roof_tile').toUpperCase() }}</td>
            </tr>
            <tr v-if="!this.validSteps.includes('safeProducts')">
              <td>3. {{ $t('step.roof_security').toUpperCase() }}</td>
            </tr>
            <tr v-if="!this.validSteps.includes('alternatives')">
              <td>4. {{ $t('step.alternatives').toUpperCase() }}</td>
            </tr>
          </table>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: ['activeStep'],
  methods: {
    goToStep(step) {
      this.$store.dispatch('goToStep', step);
    },
    stepIsValid(stepNames = []) {
      if (typeof stepNames === 'string') {
        return this.validSteps.includes(stepNames);
      } else {
        stepNames.forEach((step) => {
          if (!this.validSteps.includes(step)) return false;
        });
        return true;
      }
    },
    getSelectedValue(field) {
      return this.$store.getters.getSelectedValue(field);
    },
    formatValue(value) {
      if (value === null || value === 0 || value.length === 0 || value === false) return false;
      if (Array.isArray(value)) return value.join('+');
      if (!isNaN(Number(value))) return Number(value).toLocaleString();
      return value;
    },
  },
  computed: {
    validSteps() {
      let steps = this.$store.getters.getSteps;
      let validSteps = [];
      steps.forEach((step) => {
        let validFields = step.requierdFileds.length;
        step.requierdFileds.forEach((reqField) => {
          let fieldValue = this.$store.getters.getSelectedValue(reqField);
          if (!fieldValue || fieldValue.length === 0) validFields--;
        });
        if (step.requiredType === 'single') {
          if (validFields > 0) validSteps.push(step.id);
        } else {
          if (validFields === step.requierdFileds.length) validSteps.push(step.id);
        }
      });
      return validSteps;
    },
    SafeProductsText() {
      let textArr = [];
      if (this.$store.getters.getSelectedValue('roofSafetyLengths').length > 0) textArr.push('Snörasskydd');
      if (this.$store.getters.getSelectedValue('roofBridgeLengths').length > 0) textArr.push('Takbrygga');
      if (this.$store.getters.getSelectedValue('roofRailLengths').length > 0) textArr.push('Nock- & Takfotsräcke');
      if (this.$store.getters.getSelectedValue('roofHatchSafetyAmount').length > 0) textArr.push('Skyddsräcke taklucka');

      return textArr.join(', ');
    },
    textAlternatives() {
      let textArr = [];
      let roofTilt = this.$store.getters.getSelectedValue('roofTilt');
      let snowZone = this.$store.getters.getSelectedValue('snowZone');
      let roofLength = this.$store.getters.getSelectedValue('roofLength');
      let accessoryColor = this.$store.getters.getSelectedValue('accessoryColor');

      if (roofTilt) textArr.push(roofTilt);
      if (snowZone) textArr.push(snowZone);
      if (roofLength) textArr.push(roofLength);
      if (accessoryColor) textArr.push(accessoryColor);

      return textArr.join(', ');
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/styles/_variables.sass"
@import "@/assets/styles/_mixins.sass"
.breadcrumbs
  display: flex
  flex-flow: row nowrap
  min-height: 72px
  display: flex
  justify-content: center
  padding: $size-6
  ul
    display: flex
    flex-flow: row nowrap
  li
    display: flex
    position: relative
    margin-left: $size-5
  li + li
    &::before
      content: '|'
      padding-right: $size-8
      font-size: $size-2
      font-weight: 300
      line-height: 1
      color: $grey-lighter
  button
    text-transform: uppercase
    line-height: $leading-tight
    display: block
    font-size: $size-7
    background-color: transparent
    color: rgb(134, 137, 143)
    font-weight: $weight-semibold
    border: none
    border-bottom: 2px solid rgb(134, 137, 143, 0)
    text-decoration: none
    cursor: pointer
    &:hover
      border-color: rgb(134, 137, 143, 1)
    &.disabled:hover
      border-color: transparent
      cursor: not-allowed
  svg
    width: 15px
    color: transparent
  >div
    display: flex
    flex-flow: row nowrap
    align-items: center
    position: relative
  ul table
    white-space: nowrap
    border: 1px solid rgba(200, 200, 200, 1)
    display: none
    position: absolute
    background: white
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.418)
    padding: 5px
    z-index: 10
    top: calc(100% + 5px)
    left: 10px
  li:hover table
    display: block
table td
  padding: 0 2px

.active
  button
    border-color: black,
    color: black
.valid
  svg
    color: green
@media print
  .non-printable, .bg-svg
    display: none
</style>

<style>
.test {
  background: rgba(0, 0, 0, 0.418);
}
</style>
