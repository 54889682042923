var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hero-head non-printable" }, [
    _c(
      "nav",
      {
        staticClass: "breadcrumbs is-hidden-touch",
        attrs: { "aria-label": "breadcrumbs" },
      },
      [
        _c("ul", [
          _c(
            "li",
            {
              class: {
                valid: _vm.stepIsValid("chooseRoof"),
                active: _vm.activeStep === 0,
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 512 512",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToStep(0)
                    },
                  },
                },
                [_vm._v("1. " + _vm._s(_vm.$t("step.roof_type")))]
              ),
              _vm.stepIsValid("chooseRoof")
                ? _c("table", [
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("breadcrumb.roof_type")) + ":"),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.formatValue(_vm.getSelectedValue("rooftype"))
                          )
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "li",
            {
              class: {
                valid: _vm.stepIsValid("chooseRoofTile"),
                active: _vm.activeStep === 1,
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 512 512",
                    color: "green",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  class: { active: _vm.stepIsValid("chooseRoof") },
                  on: {
                    click: function ($event) {
                      return _vm.goToStep(1)
                    },
                  },
                },
                [_vm._v("2. " + _vm._s(_vm.$t("step.roof_tile")))]
              ),
              _vm.stepIsValid("chooseRoofTile")
                ? _c("table", [
                    _vm.formatValue(_vm.getSelectedValue("rooftile"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.roof_tile")) + ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("rooftile")
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formatValue(_vm.getSelectedValue("rooftileTreatment"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.tile_treatment")) + ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("rooftileTreatment")
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formatValue(_vm.getSelectedValue("rooftileColor"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.tile_color")) + ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("rooftileColor")
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "li",
            {
              class: {
                valid: _vm.stepIsValid("safeProducts"),
                active: _vm.activeStep === 2,
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 512 512",
                    color: "green",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToStep(2)
                    },
                  },
                },
                [_vm._v("3. " + _vm._s(_vm.$t("step.roof_security")))]
              ),
              _vm.stepIsValid("safeProducts")
                ? _c("table", [
                    _vm.formatValue(_vm.getSelectedValue("roofSafetyLengths"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.roof_safety_length")) +
                                ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("roofSafetyLengths")
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formatValue(_vm.getSelectedValue("roofBridgeLengths"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.roof_bridge_length")) +
                                ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("roofBridgeLengths")
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formatValue(_vm.getSelectedValue("roofRailLengths"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.roof_rail_length")) +
                                ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("roofRailLengths")
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formatValue(
                      _vm.getSelectedValue("roofHatchSafetyAmount")
                    )
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("breadcrumb.roof_hatch_safety_amount")
                              ) + ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("roofHatchSafetyAmount")
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "li",
            {
              class: {
                valid: _vm.stepIsValid("alternatives"),
                active: _vm.activeStep === 3,
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 512 512",
                    color: "green",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z",
                      fill: "currentColor",
                    },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goToStep(3)
                    },
                  },
                },
                [_vm._v("4. " + _vm._s(_vm.$t("step.alternatives")))]
              ),
              _vm.stepIsValid("alternatives")
                ? _c("table", [
                    _vm.formatValue(_vm.getSelectedValue("accessoryColor"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.accessory_color")) + ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("accessoryColor")
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formatValue(_vm.getSelectedValue("roofTilt"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.roof_angle")) + ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("roofTilt")
                                )
                              ) + "°"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formatValue(_vm.getSelectedValue("roofLength"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.roof_pitch")) + ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("roofLength")
                                )
                              ) + "m"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.formatValue(_vm.getSelectedValue("snowZone"))
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$t("breadcrumb.snow_zone")) + ":"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatValue(
                                  _vm.getSelectedValue("snowZone")
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "li",
            {
              class: {
                valid: _vm.validSteps.length === 5,
                active: _vm.activeStep === 4,
              },
            },
            [
              _c(
                "button",
                {
                  class: { disabled: _vm.validSteps.length < 5 },
                  on: {
                    click: function ($event) {
                      _vm.validSteps.length === 5 ? _vm.goToStep(4) : null
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("breadcrumb.summary")) +
                      "\n        "
                  ),
                ]
              ),
              _vm.validSteps.length < 5
                ? _c("table", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("breadcrumb.incomplete_steps"))),
                      ]),
                    ]),
                    !this.validSteps.includes("chooseRoof")
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              "1. " +
                                _vm._s(_vm.$t("step.roof_type").toUpperCase())
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !this.validSteps.includes("chooseRoofTile")
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              "2. " +
                                _vm._s(_vm.$t("step.roof_tile").toUpperCase())
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !this.validSteps.includes("safeProducts")
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              "3. " +
                                _vm._s(
                                  _vm.$t("step.roof_security").toUpperCase()
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !this.validSteps.includes("alternatives")
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              "4. " +
                                _vm._s(
                                  _vm.$t("step.alternatives").toUpperCase()
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }